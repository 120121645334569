//page

//トップページ

#key{
	width: calc(100% - 350px);
    margin-left: 350px;
	height: 680px;position: relative;
	background: url(../images/key2.jpg) center center no-repeat;background-size:cover;
	&:before{
		content:"";width: 100%;height: 100%;position: absolute;right:0;top:0;background: #fff;
		animation:keycoveranime 0.6s $ease1 0.2s 1 normal both;
	}
	.key-box{
		@extend .noto;color: #fff;padding: 75px 0 0 75px;
	}
	.key-title{
		font-size: 4.0rem;line-height: 1.2;text-shadow: rgba(0,0,0,0.1) 4px 4px 0;margin-bottom: 25px;position: relative;
		animation: keytextanime 0.8s $ease5 1s 1 normal both;
		.l{font-size: 4.8rem;}
		.s{font-size:3.6rem;}
	}
	.key-text{
		font-size: 1.7rem;line-height: 1.8;text-shadow: rgba(0,0,0,0.1) 2px 2px 0;position: relative;
		animation: keytextanime 0.8s $ease5 1.2s 1 normal both;
	}
}

@keyframes keycoveranime{
	0%{right:0;}
	100%{right:-101%}
}
@keyframes keytextanime{
	0%{left:-20px;opacity: 0;}
	100%{left:0;opacity: 1;}
}

#home{

}
#top-service{
	text-align: center;margin-bottom: 128px;
	.title{color: $BLACK;margin-bottom: 64px;
		.en{@extend .serif;font-size: 6.0rem;display: block;line-height: 1;margin-bottom: 20px;}
		.jp{@extend .noto;font-size: 1.8rem;display: block;line-height: 1;letter-spacing: 0.2em;text-indent: 0.2em;}
	}
	.lead{margin-bottom: 80px;line-height: 2;}
	.service-list{
		width: 960px; margin: 0 auto;
		display: flex;justify-content: space-between;flex-wrap: wrap;
	}
	.service-box{
		width: 440px;border-top:1px solid $BLACK;border-bottom:1px solid $BLACK;margin-bottom: 75px;
		padding: 150px 50px 20px;position: relative;
		&:before,&:after{
			content:"";display: block;position: absolute;
		}
		&:before{
			width: 105px;height: 105px;top:0;left:calc(50% - 52px);@include grad(lighten(saturate($RED,70%),7%),$RED,to right bottom);
			transform: rotate(45deg) ;z-index: 2;
		}
		&:after{
			width: 85px;height: 85px;top:26px;left:calc(50% - 24px);background: #efdfdf;transform: rotate(-30deg);z-index: 1;
		}
		.sub-title{
			@extend .noto;font-size: 2.1rem;margin-bottom: 20px;position: relative;
			&:before{
				position: absolute;top:-130px;left:50%;transform: translateX(-50%);
				font-size: 6.8rem;z-index: 3;color: #fff;
			}
			&.icon-inheritance:before{top:-135px;}
			&.icon-estate:before{top:-135px;}
		}
		p{text-align: left;font-size: 1.4rem;}
	}
}

#top-consul{
	background: url(../images/bg_top_consul.jpg) center center no-repeat;background-size:cover;
	padding: 128px 0;margin-bottom: 0;
	.consul-box{
		width: 960px;margin: 0 auto;
		display: flex;justify-content: space-between;
	}
	.title-box{
		width:calc(100% - 430px);
		p{
			color: #fff;margin-bottom: 30px;line-height: 2.0;
		}
	}
	.title{color: #fff;margin-bottom: 40px;
		.en{@extend .serif;font-size: 6.0rem;display: block;line-height: 1;margin-bottom: 20px;}
		.jp{@extend .noto;font-size: 1.8rem;display: block;line-height: 1;letter-spacing: 0.2em;}
	}
	
	.consul-contact{
		width: 390px;position: relative;padding: 12px 15px 0;text-align: center;
		background: rgba(255,255,255,0.8);
		&:before{
			content:"";display: block;
			width: calc(100% + 4px);height: calc(100% + 4px);border: 1px solid rgba(255,255,255,0.8);
			position: absolute;left:-3px;top:-3px;border-radius: 2px;z-index: 0;
		}
		.sub-title{
			@extend .noto;color: #fff;background: $RED;font-size: 1.8rem;padding: 10px 0;
		}
		.consul-tel,.consul-mail{
			width: 300px;margin: 0 auto;padding: 100px 0 32px;
			h5{font-weight: bold;color: $BLACK;font-size: 1.8rem;margin-bottom: 20px;}
		}
		.consul-tel{
			border-bottom: 1px solid $BLACK;background: url(../images/icon_consul_tel.png) center top 25px no-repeat;background-size:65px;
			.tel-number{font-size: 4.4rem;@extend .roboto;color: $BLACK;line-height: 1;margin-bottom: 15px;}
		}
		.consul-mail{
			background: url(../images/icon_consul_mail.png) center top 25px no-repeat;background-size:65px;
			.btn{width: 100%;font-size: 1.8rem;padding: 20px 0 20px 32px;text-align: left;}
		}
	}
}

.ie #top-consul{
	.consul-contact .consul-tel .tel-number{letter-spacing: 0;}
}

#top-info{
	padding-top: 100px;
	width: calc(100% - 350px);
	margin-left: 350px;
	.info-box{
		max-width: 960px;
		margin: 0 auto;position: relative;
	}
	.title{
		@extend .noto;font-size: 2.4rem;margin-bottom: 35px;
	}
	.link{
		position: absolute;right:0;top:15px;font-size: 1.4rem;
		a{color: $FONT_COLOR;}
	}
}
.info-list{font-size: 1.4rem;
	li{border-bottom: 1px solid $BDC;padding-left: 0 !important;
		&:before{display: none !important;}
	}
	a,.no-link{
		display: block;padding: 22px 10px;overflow: hidden;color: $FONT_COLOR;
		transition:background 0.3s $ease7;
	}
	a.icon-arrow{position: relative;padding-right: 30px;
		&:before{
			position: absolute;font-size: 2.0rem;right:5px;top:50%;transform: translateY(-50%);
			color: $RED;
		}
	}
	a:hover{
		background: $BGC_RED;
	}
	.date{@extend .roboto;color: $RED;display: block;width: 95px;float: left;}
	.category{width: 60px;float: left;font-size: 1.1rem;line-height: 1;margin-top: 2px;
		display: inline-block;text-align: center;background: #000;color: #fff;padding: 3px 0;}
	.cat-info{background: $YELLOW;}
	.cat-column{background: $BLUE;}
	.text{
		display: block;width: calc(100% - 170px);float: right;line-height: 1.3;
	}
}

#aboutus{
	
}
#greeting{
	.greeting-photo{
		width: 240px;float: right;margin: 0 0 50px 50px;
		figcaption{
			text-align: center;padding-top: 10px;
		}
		.post{display: block;}
		.name{@extend .noto;font-size: 2.1rem;color: $BLACK;}
	}
	p{line-height: 2;}
	.publication{
		display: flex;justify-content: space-between;flex-wrap: wrap;
		h4{width: 100%;}
	}
	.publication-box{
		width: 48%;margin-bottom: 20px;padding: 15px;border: 1px solid $BDC;
		border-radius: 5px;position: relative;min-height: 100px;
		p{margin-bottom: 0;}
		.image{	width: 100px;}
		.text{
			position: absolute;top:50%;left:15px;width: calc(100% - 30px);
			transform: translateY(-50%);
		}
		.image + .text{left:125px;width: calc(100% - 140px);}
		
	}
}

#service{
	.service-list{
		font-size: 1.8rem;
		li{margin-bottom: 15px;text-decoration: underline;text-decoration-color:$RED;}
	}
	.service-box{
		padding-left: 150px;margin-bottom: 60px;position: relative;
		
		&:after{
			content:"";display: block;position: absolute;
		}
		&:before{
			font-size: 6.8rem;position: absolute;top:45px;left:19px;z-index: 2;color: #fff;
		}
		&:after{
			width: 105px;height: 105px;top:30px;left:0;@include grad(lighten(saturate($RED,70%),7%),$RED,to right bottom);
			transform: rotate(45deg);z-index: 1;
		}
		&.icon-inheritance:before{top:42px;}
		&.icon-estate:before{top:42px;}
	}
}


#access{
	.train-list{
		display: flex;justify-content: space-between;
		li{width: 30%;border: 1px solid;border-radius: 5px;text-align: center;line-height: 1.3;overflow: hidden;padding: 0;
			&:before{display: none;}
		}
		.train{display: block;color: $BLACK;font-weight: bold;padding: 15px 10px;color: #fff;	}
		.root{display: block;font-size: 1.4rem;padding: 20px 10px;}
		.l{font-size: 2.4rem;}
		.marunouchi{border-color: #f62e36;
			.train{background: #f62e36;}
		}
		.ginza{border-color: #ff9500;
			.train{background: #ff9500;}
		}
		.hanzomon{border-color: #8f76d6;
			.train{background: #8f76d6;}
		}
		.hibiya{border-color: #b5b5ac;
			.train{background: #b5b5ac;}
		}
		.chiyoda{border-color: #00bb85;
			.train{background: #00bb85;}
		}
	}

}

#achieve{
	.achieve-box{
		display: flex;margin-bottom: 60px;
		.title-box{
			width: 320px;margin-right: 30px;overflow: hidden;position: relative;
			background: $BGC_GRAY;padding: 45px 15px 15px;border-radius: 2px;
		}
		.contents{width: calc(100% - 350px);}
		.title{margin-bottom: 25px;
			span{display: block;}
		}
		.number{
			background: $RED;color: #fff;text-align: center;margin-bottom: 15px;@extend .serif;
			width: 100%;position: absolute;top:0;left:0;font-size: 1.2rem;letter-spacing: 0.2em;padding: 5px 0;
		}
		.before{position: relative;padding-bottom: 30px;
			&:before{
				content:"";position: absolute;bottom:10px;left:calc(50% - 25px);
				width: 0;height: 0;border-style: solid;border-width: 18px 25px 0 25px;
				border-color: $RED transparent transparent transparent;
			}
		}
	}
	.achieve-tb{
		font-size: 1.3rem;
		th,td{border: none;border-bottom: 1px solid $BDC;padding: 12px 0;}
		tbody th{width: 70px;background: none;}
		.service{
			border-bottom: none;padding-bottom: 0;
		}
	}
	.anchor-box{
		ul{display: flex;justify-content: space-between;}
		li{width: 22%;padding-left: 0;
			&:before{display: none;}
		}
		a{display: block;text-align: center;border: 1px solid $RED;padding: 10px 0;color: $RED;
			@extend .serif;letter-spacing: 0.2em;transition:all 0.3s $ease7;
			&:hover{background: $RED;color: #fff;text-decoration: none;}
		}
	}
}


#staff{
	.page-body{overflow: hidden;}
	.staff-box{
		display: flex;width: 70%;float: left;margin-bottom: 20px;position: relative;
		&:before,&:after{content:"";width: 36px;height: 36px;position: absolute;border: 1px solid $BGC_RED;}
		&:nth-child(2n+1){float: left;
			&:before{right:-28%;bottom:100px;}
			&:after{right:-31%;bottom:115px;}
		}
		&:nth-child(2n){float: right;
			&:before{left:-28%;bottom:100px;}
			&:after{left:-31%;bottom:115px;}
		}
		.photo{width: 150px;height: 200px;position: relative;
			&:before,&:after{content:"";width: 10px;height: 10px;position: absolute;border: 1px solid $RED;}
			&:before{left:-5px;top:-5px;}
			&:after{right:-5px;bottom:-5px;}
			
		}
		.text{width: calc(100% - 150px);padding: 20px 0 20px 20px;}
		.title{line-height: 1;margin-bottom: 10px;padding-top: 0;}
		.license{font-size: 1.3rem;color: $RED;margin-bottom: 5px;}
	}
	
}

#contact{
	.contact-tb{
		th,td{border: none;padding: 30px 0;border-bottom: 1px solid $BLACK;}
		tbody th{width: 220px;background: none;padding-right: 20px;}
		.require{
			float: right;background: $RED;font-size: 1.2rem;color: #fff;display: inline-block;line-height: 1;
			font-weight: normal;padding: 2px;margin-top: 5px;
		}
		.notes{font-size: 1.2rem;color: $GRAY;padding-top: 5px;display: block;line-height: 1.2;}
	}
	.btn-area{text-align: center;}
	
	.thanks-box{
		width: 80%;margin: 0 auto;border: 1px solid $RED;text-align: center;
		padding: 100px 50px 50px;
		h3{font-size: 2.4rem;}
	}
}

#information{
	&.bn-entry{
		.page-title-wrap{
			height: 150px;
			.en{font-size: 4.0rem;}
			.jp{font-size: 1.6rem;}
		}
		.date-box{margin-bottom: 48px;border-top: 1px solid $RED;padding: 5px 0 0 2px;
			.date{@extend .roboto;color: $BLACK;display: inline-block;margin-right: 20px;font-size: 1.4rem;}
			.category{width: 60px;display: inline-block;font-size: 1.1rem;line-height: 1;margin-top: 2px;	text-align: center;
				background: #000;color: #fff;padding: 3px 0;}
			.cat-info{background: $YELLOW;}
			.cat-column{background: $BLUE;}
			
		}
		.page-body{
			h3{margin-bottom: 10px;}
		}
	}
}


#privacy{
	.page-title-wrap{
		height: 150px;
		.jp{font-size: 2.8rem;}
	}
	.page-body{
		h3{color: $BLACK;padding-top: 32px;margin-bottom: 32px;font-size: 2.1rem;}
	}
}
#sitemap{
	.page-title-wrap{
		height: 150px;
		.jp{font-size: 2.8rem;}
	}
	.sitemap-list{
		li{margin-bottom: 20px;}
	}
	footer{
		margin-top: 500px;
	}
}
.bn-thanks, .bn-thanks_magazine{
	footer{
		margin-top: 200px;
	}
}
.contact_adjust{
	footer{
		margin-top: 350px;
	}
}