//layout

// ラッパー
#wrapper{
	width: 100%;min-width: 1000px;overflow: hidden;
}

// ヘッダー
header{
	width: 100%;display: flex;padding-top: 85px;
}
.head-box{
	width: 350px;padding-top: 150px;text-align: center;position: absolute;left:0;top:0;
}
#home .head-box{
	//position: static;padding-top: 65px;
}
.logo{
	margin-bottom: 108px;
	a{
		display: block;	padding-top: 70px;font-size: 1.5rem;line-height: 1.7;	@extend .noto;color: $BLACK;
		background: url(../images/logo.png) center 0 no-repeat;background-size:228px,40px;
		&:hover{text-decoration: none;opacity: 0.7;}
	}
}
.head-contact{
	position: absolute;top:0;right:0;
	width: 385px;height: 85px;background: $BLACK;
	display: flex;
	.tel-box{
		width: 285px;color: #fff;position: relative;padding: 15px 0 0 65px;
		&:before{
			font-size: 4.0rem;position: absolute;top:20px;left: 25px;
		}
	}
	.tel-number{
		@extend .roboto;display: block;font-size: 3.0rem;letter-spacing: 0;line-height: 1.2;
	}
	.tel-text{
		font-size: 1.2rem;display: block;line-height: 1.2;
	}
	.mail-box{
		width: 100px;background: #fff;
		a{
			display: block;height: 85px;text-align: center;
			@include grad(lighten(saturate($RED,70%),7%),$RED,to bottom);
			color: #fff;font-size: 1.0rem;position: relative;padding-top: 44px;line-height: 1.2;
			transition:opacity 0.3s $ease7;
			&:before{
				font-size: 3.4rem;position: absolute;top:10px;left:50%;transform: translateX(-50%);
			}
			&:hover{opacity: 0.7;text-decoration: none;}
		}
	}
}

// グローバルナビ
#menu-btn{
	.menu-text{font-size: 1.2rem;@extend .serif;color: $RED;position: relative;display: inline-block;
		letter-spacing: 0.25em;text-indent: 0.25em;margin-bottom: 45px;
		&:before,&:after{content:"";display: block;width: 25px;height: 1px;background: $RED;
			position: absolute;top:calc(50% - 1px);
		}
		&:before{left:-35px;}
		&:after{right:-35px;}
	}
}
.navi-wrap{

}
#gnavi{
	@extend .noto;font-size: 1.8rem;letter-spacing: 0.2em;margin-bottom: 80px;
	position: relative;width: 100%;font-size: 0;
	.home{display: none;}
	ul{
		width: 100%;display: flex;justify-content: center;flex-flow: row-reverse;
	}
	li{
		width: 27px;margin: 0 12px;
	}
	a{color: $BLACK;transition:color 0.3s $ease7;display: inline-block;font-size: 1.8rem;
		overflow: hidden;	position: relative;writing-mode: vertical-rl;letter-spacing: 0.2em;
		&:before{
			content:"";display: block;height: 100%;width:1px;background: $RED;
			position: absolute;right:0;top:-100%;transition: top 0.3s $ease7;
		}
	}
	.active a,a:hover{
		color: $RED;text-decoration: none;
		&:before{top:0;}
	}
}

.head-magazine{
    width: 80%;
    margin: 0 auto 80px;
    display: block;
	.magazine-title{font-size: 1.4rem;@extend .serif;color: $RED;position: relative;display: inline-block;
		letter-spacing: 0.25em;text-indent: 0.25em;margin-bottom: 0;
		&:before,&:after{content:"";display: block;width: 25px;height: 1px;background: $RED;
			position: absolute;top:calc(50% - 1px);
		}
		&:before{left:-35px;}
		&:after{right:-35px;}
	}
	.magazine-tb{
		margin-bottom: 10px;
		td,th{
			display: block;
			border: none;
		}
		th{
			width: 100%;
			padding: 15px 12px;
			border: none;
		}
		input::placeholder {
			font-size: 1.4rem;
		}	
	}
	.btn-large{
		font-size: 1.7rem;
		padding: 10px 40px;		
	}
}
#email-magazine-error{
	color: $RED;
	font-size: 1.3rem;
}

.head-share{
	height: 88px;width: 180px;border-radius: 44px;margin: 0 auto;background: $BGC_GRAY;
	.share-title{
		@extend .serif;font-size: 1.2rem;padding-top: 15px;margin-bottom: 15px;letter-spacing: 0.25em;text-indent: 0.25em;
	}
	.share-list{
		display: flex;justify-content: center;
		li{margin: 0 10px;}
		a{
			text-indent: -9999px;display: block;overflow: hidden;transition:color 0.3s $ease7;
			width: 32px;height: 32px;position: relative;color: #bbb;font-size: 1.8rem;
			&:before{position: absolute;top:0;left:0;width: 100%;height: 100%;text-indent: 0;}
		}
		.icon-facebook:hover{color: #3B5998;}
		.icon-twitter:hover{color: #55acee;}
	}
}


article{
	width: calc(100% - 350px);margin-left: 350px;min-height: 760px;
}
#home article{
	width: 100%;margin-left: 0;//padding-top: 100px;
}
.main{

}



// フッター
footer{
	width: 100%;
	.foot-box{
		display: flex;
	}
	.foot-address{
		width: 43%;position: relative;font-size: 1.4rem;background: $RED;color: #fff;
		p{margin-bottom: 25px;}
	}
	.address-box{
		display: inline-block;min-width: 362px;
		position: absolute;top:50%;left:50%;transform: translate(-50%,-50%);
	}
	.foot-title{
		font-weight: bold;margin-bottom: 35px;white-space: nowrap;
		padding-top: 50px;background: url(../images/foot_logo.png) 0 0 no-repeat;background-size:190px 35px;
	}
	.foot-navi{
		li{display: inline-block;margin-right: 20px;}
		a{color: #fff;}
	}
	.foot-map{
		width: 57%;height: 500px;position: relative;
	}
	#gmap{
		width: 100%;height:100%;position: absolute;left:0;top:0;
	}
	.pagetop{
		position: fixed;bottom:-50px;right:0;	width: 50px;
		transition:bottom 0.5s $ease1;
		a{display: block;height: 50px;position: relative;overflow: hidden;
			.text{display: none;}
			&:before{
				position: absolute;top:50%;left:50%;transform:  translate(-50%,-50%) rotate(-90deg);color: $RED;font-size: 2.8rem;
				transition: top 0.3s $ease1; 
			}
			&:after{
				content:"";width: 10px;height: 2px;background: $RED;
				position: absolute;top:5px;opacity: 0;left:calc(50% - 5px);
				transition: top 0.3s $ease1,opacity 0.2s $ease1;
			}
			&:hover{
				text-decoration: none;
				&:after{top:10px;opacity: 1;}
				&:before{top:calc(50% - 5px);}
			}
		}
		&.show{
			bottom:0;
		}
	}
	.copyright{
		background: #3b3232;text-align: center;display: block;padding: 18px 0;@extend .roboto;color: #fff;font-size: 1.1rem;
	}
}
