//form
input,textarea,select{font-family:$FONT_FAMILY;color: $FONT_COLOR;}
.ie{
	input,textarea,select{font-family:$IE_FF;}
}
input[type="button"],input[type="submit"]{
	appearance: none; border: none; margin: 0; border-radius:0;cursor: pointer;font-family:$FONT_FAMILY;
}
input[type="text"],input[type="number"],input[type="password"],input[type="email"],textarea{
	appearance: none; width:100%;	border: 1px solid $BDC;	padding:3px 5px; border-radius:0;font-size: 1.6rem;
	background: transparent;
}
input[type="radio"],input[type="checkbox"]{margin-right: 5px;}
.mac{
	input[type="radio"],input[type="checkbox"]{vertical-align:0.2em;}
}
.select-wrap{
	position: relative; display: inline-block;
	&:after{content:"›";color: $BLACK;position: absolute;right:8px;top:calc(50% - 0.5em);line-height: 1;
		transform: rotate(90deg);z-index: 1;font-size: 1.4rem;
	}
	&:before{
		content:"";width: 1px;height: 100%;position: absolute;right:25px;top:0;background: $BDC;
	}
}
select{
	font-size: 1.6rem;margin: 0;
	border: 1px solid $BDC;appearance: none;border-radius: 0;
	padding: 3px 33px 3px 8px;background: transparent;position: relative;z-index: 2;
}
select::-ms-expand { display: none;}

:placeholder-shown {color: #bbb; }
::-webkit-input-placeholder {color:#bbb; }
::-moz-placeholder {color: #bbb; opacity: 1; }
:-ms-input-placeholder {color:#bbb; }

#back{
	margin-right: 30px;
}

form{
	.input-box{display: inline-block;}
	#sei,#mei,#seikana,#meikana{width: 120px;}
	#sei,#seikana{margin-right:15px;}
	#postal-code{width: 120px;}
	#ckemail{margin-top: 10px;	}
	#tel,#fax{width: 180px;}
	#comment{height: 150px;}
}
