//mobile
body{font-size: 1.4rem;}
input[type="text"],input[type="number"],input[type="password"],input[type="email"],textarea,select{font-size: 1.4rem;}
p img,figure img{@include max(w);}

.spdisp{display: block;}
.sphide{display: none;}

.xs{font-size: 1.0rem;}
.s{font-size: 1.2rem;}
.l{font-size: 1.6rem;}

.btn{
	font-size: 1.6rem;
}
.box{
	padding: 20px;
}
.block{
	padding-top: 40px;margin-bottom: 40px;
}

.page-title-wrap{
	height: 150px;background-position: center center;
	.en{font-size: 4.4rem;margin-bottom: 8px;}
	.jp{font-size: 1.4rem;}
}
.page-body{
	padding: 40px 7%;
	h3{font-size: 2.4rem;margin-bottom: 28px;}
	h4{font-size: 1.8rem;padding-top: 14px;margin-bottom: 28px;}
	h5{font-size: 1.6rem;}
}

.breadcrumb{padding-right: 10px;}

table{font-size: 1.4rem;
	&:not(.nobreak){
		tr,th,td{display: block;border: none;padding: 0;width: 100% !important;}
		tr{border-bottom: 1px dashed $BDC;padding-bottom: 14px;margin-bottom: 14px;}
		th{margin-bottom: 5px;}
		tbody th{background: none;}
	}
}
.head-box{
	padding-top: 80px !important;
}
.logo{
	margin-bottom: 32px;
	a{
		background-size: 171px 30px;padding-top: 48px;
		font-size: 1.2rem;
	}
}

.head-contact{
	height: 50px;width: 100px;
	.tel-box{
		padding: 0;width: 50px;
		&:before{font-size: 2.6rem;top:11px;left:50%;transform: translateX(-50%)}
		a{display: block;height: 50px;width: 50px;position: absolute;left:0;top:0;z-index: 1;}
	}
	.tel-number,.tel-text{
		display: none;
	}
	.mail-box{
		width: 50px;
		a{height: 50px;
			&:before{font-size: 2.4rem;top:13px}
		}
	}

}

#menu-btn{
	width: 50px;height: 50px;
	.menu-line{width: 20px;left:15px;
		&:nth-child(1){top:17px;}
		&:nth-child(2){top:24px;}
		&:nth-child(3){top:31px;}
	}
}
#gnavi{
	li{margin: 0 14px;}
	a{font-size: 1.6rem;}
}
.head-magazine{
	.magazine-title{
		margin-bottom: 25px;
	}
	.magazine-tb{
		 th{
			padding: 0;
		 }
	}
}
#menu.open{
	#menu-btn{
		.menu-line{
			&:nth-child(1){top:21px;}
			&:nth-child(3){top:21px;}
		}
	}
}

footer{
	.foot-box{
		text-align: center;
		a{color: #fff;}	
		p{font-size: 1.2rem;}
	}
	.address-box{
		min-width:0;
	}
	.foot-title{
		padding: 60px 0 0 0;background-position: center 0;font-size: 1.3rem;
	}
	.foot-navi{padding-top: 10px;font-size: 1.2rem;}
	.foot-map{padding-bottom: 75%;}
	.copyright{font-size: 1.0rem;padding: 12px 0;}
}

#key{
	height: 400px;margin-top: 32px;
	&:after{
		content:"";position: absolute;top:0;left:0;z-index: 2;width: 100%;height: 100%;background: rgba(17,80,151,0.3);
	}
	.key-box{
		padding: 32px 0 0 6%;width: 315px;position: relative;z-index: 3;
		
	}
	.key-title{
		font-size: 2.4rem;
		.l{font-size: 2.8rem;}
		.s{font-size: 2.1rem;}
	}
	.key-text{
		font-size: 1.4rem;
	}
}

#top-service{
	margin-bottom: 80px;
	.title{margin-bottom: 32px;
		.en{font-size: 4.4rem;margin-bottom: 10px;}
		.jp{font-size: 1.6rem;}
	}
	.service-box{
		width: 100%;
	}
}

#top-consul{background: none;padding: 0;
	.consul-box{
		padding: 0;
	}
	.title{
		.en{font-size: 4.4rem;margin-bottom: 10px;}
		.jp{font-size: 1.6rem;}
	}
	.title-box{
		background: url(../images/bg_top_consul.jpg) center center no-repeat;background-size:cover;
		padding: 80px 6% 40px;margin-bottom: 40px;
		p{margin-bottom: 20px;}
	}
	.consul-contact{
		width: 88%;margin: 0 auto 40px;border: 1px solid $RED;
		&:before{border-color:$RED;}
		.consul-tel,.consul-mail{
			width: 100%;
		}
		.consul-tel{border-bottom: 1px solid $BLACK;
			a{display: block;position: relative;z-index: 2;}
			.tel-number{line-height: 1;font-size: 3.6rem;}
		}
		.consul-mail{
			.btn{width: 90%;}
		}
	}
}

#top-info{
	.title{
		font-size: 2.1rem;margin-bottom: 20px;
	}
}
.info-list{
	.date,.category{float: none;display: inline-block;}
	.date{font-size: 1.2rem;}
	.category{font-size: 1.0rem;}
	.text{float: none;width: 100%;font-size: 1.2rem;padding-top: 5px;}
}

#greeting{
	.greeting-photo{
		width: 60%;float: none;margin: 0 20% 30px;
	}
	
	.publication-box{
		padding: 10px;
		.image{width: 60px;
			img{@include max(w);}
		}
		.image + .text{
			width: calc(100% - 90px);left:80px;
		}
		a{
			&:before{content: "";position: absolute;top:0;right:0;bottom:0;left:0;z-index: 1;}
		}
	}
}

#access{
	.train-list{flex-wrap: wrap;
		li{width: 48%;margin-bottom: 10px;}
		.l{font-size: 1.8rem;}
		.train{margin-bottom: 5px;padding: 10px;}
		.root{padding: 12px 10px 15px;}
	}
	.link-gmap{
		font-size: 1.2rem;top:44px;
		.btn{padding: 10px 12px;}
	}
}

#service{
	.page-body h3{font-size: 2.1rem;}
	.service-list{
		font-size: 1.4rem;
		li{margin-bottom: 10px;}
	}
	.service-box{
		padding: 150px 0 0;
		&:before{
			top:38px;left: 50%;transform:translate(-50%);
		}
		&:after{
			top:20px;left:50%;transform:translate(-50%) rotate(45deg);
		}
		&.icon-inheritance:before{top:32px;}
		&.icon-estate:before{top:32px;}
		h4{text-align: center;margin-bottom: 16px;}
	}
}


#achieve{
	.achieve-box{
		display: block;
		.title-box{
			width: 100%;border-radius: 2px 2px 0 0 ;
		}
		.contents{width: 100%;padding: 10px 15px;border: 1px solid $BGC_GRAY;border-radius: 0 0 2px 2px;}
		.title{margin-bottom: 10px;}
		.before{
			padding-bottom: 20px;
		}
		p{margin-bottom: 20px;}
	}
	.achieve-tb{
		th,td{border: none;padding: 5px 0;}
	}
	.anchor-box{
		ul{border-left: 1px solid $RED;}
		li{width: 25%;}
		a{font-size: 1.1rem;letter-spacing: 0.05em;border-left: none;}
	}
}

#staff{
	.page-body h3{font-size: 2.1rem;}
	.staff-box{width: 100%;float: none;
		&:before,&:after{display: none;}
		.photo{width: 120px;height: 160px;}
		.text{width: calc(100% - 120px);padding-top: 10px;padding-bottom: 0;}
		.license{font-size: 1.1rem;}
		.message{font-size: 1.3rem;}
	}
}

#contact{
	.contact-tb{
		th,td{border: none;padding: 0;}
		tr{padding: 20px 0;border-bottom: 1px solid $BLACK;margin-bottom: 0;}
		tbody th{width: 100%;padding-right: 0;margin-bottom: 10px;}
	}
	.btn-area{
		display: flex;flex-flow: column-reverse;
		#back{margin: 20px 0 0;}
	}
	.thanks-box{width: 90%;padding: 60px 20px;
		h3{font-size: 1.8rem;}
		.text{text-align: left;}
	}
}

#information{
	&.bn-entry{
		.breadcrumb{display: none;}
		.page-title-wrap{
			.en{font-size: 4.4rem;}
			.jp{font-size: 1.4rem;}
		}
		.date-box{
			.date{font-size: 1.2rem;}
			.category{font-size: 1.0rem;}
		}
		.page-body{
			h3{font-size: 2.1rem;}
		}
	}
}
#privacy{
	.page-title-wrap .jp{font-size: 2.1rem;}
	.page-body{
		h3{font-size: 1.8rem;}
		h4{font-size: 1.6rem;}
	}
}
#sitemap{
	.page-title-wrap .jp{font-size: 2.1rem;}
}