//small pc
.page-title-wrap{
	.page-title{left:45px}
}

.page-body{
	padding: 80px 45px;
}

#greeting{
	.greeting-photo{
		width: 200px;margin: 0 0 30px 30px;
		img{@include max(w);}
		.post{font-size: 1.4rem;}
		.name{font-size: 1.8rem;}
	}
}
#profile{
	.profile-tb{
		tbody th{width: 120px;}
	}
}
#access{
	.train-list{
		li{width: 32%;}
	}
}

#achieve{
	.achieve-box{
		.title-box{
			width: 260px;margin-right: 20px;
		}
		.contents{width: calc(100% - 280px);}
	}
}