//plugins

/*exValidation*/
//吹き出し
.errMsg{margin-left:1em; padding-bottom:1px; display:block; line-height:1.4; text-indent:-.9em;}
.formError{
padding-bottom:5px; display:block; margin-left:-20px; 
	.formErrorContent{
		padding:3px 8px; width:auto; position:relative; color:#fff; background:$RED; font-size:1.2rem; _z-index:1; 
	}
	.formErrorArrow{	width:20px; height:20px; position:absolute; bottom:-10px; left:5px; 	_z-index:0; 
		div{	margin:0 auto; display:block; height:1px; background:$RED; line-height:0px; font-size:0px; }
		.line10{	width:19px;} 
		.line9{	width:17px;} 
		.line8{	width:15px;} 
		.line7{	width:13px;} 
		.line6{	width:11px;} 
		.line5{	width:9px;} 
		.line4{	width:7px;} 
		.line3{	width:5px;} 
		.line2{	width:3px;}
		.line1{	width:1px;} 
	}
	.fadeOut{opacity: 0.2;}
}
.err{background:#ffecec !important;}

//入力エリア下
.errMsg{margin-left:1em; padding-bottom:1px; display:block; line-height:1.4; text-indent:-.9em;}
.formError{
padding-top:5px; display:block;
	.formErrorContent{
		padding:3px 8px; width:auto; position:relative; color:#fff; background:$RED; font-size:1.2rem; _z-index:1; 
	}
	.formErrorArrow{display: none;}
	.fadeOut{opacity: 0.2;}
}
.err{background:#ffecec !important;}