//tablet
.tbdisp{display: block;}
.tbhide{display: none;}

.page-title-wrap{border-left: none;
	.page-title{left:7%;}
}

.page-body{
	padding: 80px 7%;
}

#wrapper{
	min-width: 0;position: relative;
}
header{
	display: block;padding-top: 0;
}
.head-box{
	width: 100%;padding-top: 85px;position: static;
}
.logo{
	margin-bottom: 48px;
}
.head-contact{
	height: 60px;width: 310px;z-index: 999;
	.tel-box{
		padding: 6px 0 0 58px;width: 250px;
		&:before{font-size: 3.6rem;top:12px;left:18px;}
	}
	.tel-number{
		font-size: 2.6rem;
	}
	.tel-text{
		font-size: 1.1rem;
	}
	.mail-box{
		width: 60px;
		a{height: 60px;padding-top: 0;
			&:before{
				font-size: 3.0rem;top:15px;
			}
		}
		.mail-text{display: none;}
	}
}

#menu-btn{
	display: block;width: 60px;height: 60px;background: #000;
	position: absolute;left:0;top:0;cursor: pointer;z-index: 999;overflow: hidden;
	.menu-line{display: block;width: 24px;height: 1px;
		background: #fff;position: absolute;left:calc(50% - 12px);
		&:nth-child(1){top:22px;transition: top 0.1s $ease2 0.2s,transform 0.2s $ease2 0.05s;}
		&:nth-child(2){top:30px;transition: opacity 0.1s $ease2 0s;}
		&:nth-child(3){top:38px;transition: top 0.1s $ease2 0.2s,transform 0.2s $ease2 0.05s;}
	}
	.menu-text{
		display: none;
	}
}
.navi-wrap{
	display: block;visibility: hidden;opacity: 0;transition: all 0.5s $ease5;
	width: 100%;height: 100%;padding: 90px 0 40px;background: rgba(255,255,255,0.9);backdrop-filter:blur(3px);
	overflow: auto;	-webkit-overflow-scrolling:touch;
	position: fixed;z-index: 998;top:0;left:0;
}
#gnavi{
	position: relative;z-index: 1;width: 100%;margin-bottom: 60px;
	li{margin: 0 18px;}
	.home{display: block;}
}
.head-share{
	background: transparent;border: 1px solid $BDC;
	.icon-facebook{color: #3B5998 !important;}
	.icon-twitter{color: #55acee !important;}
}

#menu.open{
	#menu-btn{
		.menu-line{
			&:nth-child(1){top:30px;transform: rotate(-135deg);transition: top 0.1s $ease2 0.05s,transform 0.2s $ease2 0.1s;}
			&:nth-child(2){opacity: 0;}
			&:nth-child(3){top:30px;transform: rotate(135deg);transition: top 0.1s $ease2 0.05s,transform 0.2s $ease2 0.1s;}
		}
	}
	.navi-wrap{visibility: visible;opacity: 1;}
}

article{
	width: 100%;margin-left: 0;min-height: initial;
}

footer{
	.foot-box{display: block;}
	.foot-address{width: 100%;padding: 30px 6%;}
	.address-box{position: static;transform: translate(0,0);
		p{margin-bottom: 15px;}
	}
	.foot-title{
		padding: 0 0 0 220px;background-position: 0 15px;
	}
	.foot-map{width: 100%;height: 0;padding-bottom: 50%;}
}
#key{
	margin-top: 60px;width: 100%;height: 500px;margin-left: 0;
	.key-title{
		font-size: 3.6rem;
		.l{font-size: 4.2rem;}
		.s{font-size: 3.2rem;}
	}
	.key-text{
		font-size: 1.6rem;text-shadow: rgba(0,0,0,0.5) 1px 1px 0;
	}
}

#top-service{
	.lead{
		padding: 0 6%;text-align: left;
		br{display: none;}
	}
	.service-list{
		width: 100%;padding: 0 6%;
	}
	.service-box{
		width: 48%;padding: 150px 0 20px;
	}
}

#top-consul{padding: 80px 0;
	.consul-box{
		width: 100%;padding: 0 6%;display: block;
	}
	.title-box{width: 100%;}
	.consul-contact{width: 100%;display: flex;flex-wrap:wrap;justify-content: space-between;
		.sub-title{width: 100%;font-size: 1.6rem;padding: 5px 0;}
		.consul-tel,.consul-mail{width: 48%;padding: 80px 0 15px;background-position: center top 10px;
			h5{font-size: 1.6rem;}
		}
		.consul-tel{border: none;
			.tel-number{font-size: 3.0rem;line-height: 36px;}	
		}
		.consul-mail{
			.btn{font-size: 1.6rem;padding: 15px 0 15px 15px;}
		}
	}
}

#top-info{
	width: 100%;
	margin-left: 0;
	.info-box{width: 100%;padding: 0 6%;}
	.link{
		right:6%;top:10px;
	}
}
.info-list{
	a,.no-link{padding-top: 12px;padding-bottom: 12px;}
}
#greeting{
	.publication-box{
		width: 100%;
		.text{position: static;transform: translateY(0);width: 100%;}
		.image + .text{position: absolute;transform: translateY(-50%);}
	}
}
#access{
	position: relative;
	.train-list{
		li{font-size: 1.3rem;}
	}
	.link-gmap{
		font-size: 1.4rem;position: absolute;right:0;top:80px;
	}
}
#sitemap{
	footer{
		margin-top: 0;
	}
}
.bn-thanks, .bn-thanks_magazine{
	footer{
		margin-top: 0;
	}
}
.contact_adjust{
	footer{
		margin-top: 0;
	}
}