//module
a{color:$BLUE;text-decoration: none;
	&:hover{text-decoration: underline;
		img{opacity:0.7; transition: opacity 0.3s $ease7;}
	}
}
// clearfix
.cf:after{content:"";display:block;	clear:both;}

.center {	text-align: center;}
.right {	text-align: right;}
.left {	text-align: left;}
.fl-left {	float: left;	width: auto;}
.fl-right {	float: right;	width: auto;}
.cl{clear:both;}

.btn{
	display: inline-block !important;color: $RED;position: relative;line-height: 1;font-weight: bold;
	padding: 15px 32px;border: 1px solid $RED !important;background: #fff;
	transition:all 0.3s $ease7;overflow: hidden;
	&.icon-arrow{
		padding: 15px 90px 15px 32px;
		&:before{
			position: absolute;right:15px;top:50%;transform: translateY(-50%);font-size: 2.4rem;
		}
	}
	&:hover{
		background: $RED;color: #fff;text-decoration: none;
		&.icon-arrow:before{
			animation: arrowanime 0.7s $ease4 0.1s 1 normal both;
		}
	}
}
.btn-large{
	font-size: 2.1rem;padding: 18px 60px;
}
.btn-small{
	font-size: 1.4rem;padding: 12px 20px;
}
.btn-back{
	border-color: $GRAY !important;color: $GRAY;
	&:hover{background: $GRAY;}
}

@keyframes arrowanime{
	0%{right:15px;opacity: 1;}
	30%{right:-30px;opacity: 0;}
	31%{right:50%;opacity: 0;}
	100%{right:15px;opacity: 1;}
}
.arrow{
	color: $RED;position: relative;display: inline-block;padding-left: 15px;
	&:before{
		color: $RED;font-size: 1.4rem;position: absolute;left:0;top:50%;transform: translateY(-50%);
	}
}

//フォントサイズ
.l{font-size:1.8rem;}
.s{font-size:1.4rem;}
.xs{font-size:1.2rem;}

//フォントカラー
.red{	color: $RED;}


.box{
	background-color: $BGC_GRAY;padding:24px;margin-bottom: 32px;
	>:first-child{padding-top: 0 !important;}
	h4,h5,h6{margin-bottom: 12px !important;}
	p,ul,dl,ol{margin-bottom: 12px !important;}
	@include last-mb0();
}
.block{
	padding-top: 80px;margin-bottom: 80px;
	&:first-child{padding-top: 0;}
}

.tbdisp{display: none;}
.spdisp{display: none;}

.page-title-wrap{
	height: 300px;background: $BGC_GRAY;position: relative;margin-bottom: 10px;border-left: 1px solid $RED;
	.page-title{
		position: absolute;top:50%;left:80px;transform: translate(0,-50%);
	}
	.en{@extend .serif;font-size: 6.0rem;display: block;line-height: 1;margin-bottom: 20px;
		&:first-letter{
			color: $RED;
		}
	}
	.jp{@extend .noto;font-size: 2.1rem;display: block;line-height: 1;letter-spacing: 0.2em;}
}


#aboutus .page-title-wrap{background: url(../../aboutus/images/bg_title_aboutus.jpg) center center no-repeat; background-size:cover;}
#achieve .page-title-wrap{background: url(../../achieve/images/bg_title_achieve.jpg) center center no-repeat; background-size:cover;}
#staff .page-title-wrap{background: url(../../staff/images/bg_title_staff.jpg) center center no-repeat; background-size:cover;}
#service .page-title-wrap{background: url(../../service/images/bg_title_service.jpg) center center no-repeat; background-size:cover;}
#contact .page-title-wrap{background: url(../../contact/images/bg_title_contact.jpg) center center no-repeat; background-size:cover;}
#information .page-title-wrap{background: url(../../information/images/bg_title_information.jpg) center center no-repeat; background-size:cover;}

.main{
	
}
.page-body{padding: 80px;max-width: 1150px;margin: 0 auto;
	h3{
		@extend .noto;font-size: 2.8rem;color: $RED;margin-bottom: 54px;letter-spacing: 0.1em;line-height: 1.3;
	}
	h4{
		@extend .noto;font-size: 2.1rem;color: $BLACK;margin-bottom: 32px;padding-top: 16px;line-height: 1.3;
	}
	h5{
		font-size: 1.8rem;color: $BLACK;line-height: 1.3;
	}
	h6{
		font-weight: bold;line-height: 1.3;
	}

	p,ul,ol,dl{margin-bottom: 32px;}
	
	ul{
		li{padding-left: 15px;position: relative;
			&:before{content:"";width: 4px;height: 4px;display: block;background: $RED;
				position: absolute;left:2px;top:calc(0.9em - 2px);border-radius: 2px;
			}
		}
	}
}

//パンくずリスト
.breadcrumb{font-size:1rem;text-align: right;padding-right: 25px;
	li{display: inline-block;
		&:before{content:"›";display: inline-block;margin: 0 5px 0 2px;color: $BDC;}
		&:first-child:before{display: none;}
	}
}

//表組み
table{width: 100%;font-size: $FONT_SIZE;line-height: 1.5;margin-bottom: 30px;}
th,td{padding:20px 12px;border: 1px solid $BDC;vertical-align: top;}
thead th{	font-weight: bold; text-align: center;}
tbody th{ width: 15%;font-weight: bold;background: $BGC_GRAY;}

//罫線
hr{	border: none;border-bottom: 5px double $BDC;padding-top:80px;margin-bottom: 80px;}

//ページネーション
.paginate{padding-top: 64px;
	ul{display: flex;justify-content: center;
		li{margin: 0 5px;padding-left: 0;
			&:before{display: none;}
		}
	}
	a,span{display: block;padding: 5px 3px 6px;min-width: 28px;text-align: center;line-height: 1;}
	a{border: 1px solid $BLUE;
		&:hover{
			background: $BLUE;color: #fff;text-decoration: none;
		}
	}
	span{border: 1px solid $BLACK;}

	.disabled{color: #ccc;}
	.current{
		a{cursor:default;color: $FONT_COLOR;
			&:hover{background: none;text-decoration: none;}
		}
	}
	.prev,.next{font-size: 1.8rem;
		a,span{border: none;padding: 3px 3px 6px;}
		a:hover{background: none;color: $BLUE;}
	}
}
#expressnews{
	.page-title-wrap {
		background: url(../../information/images/bg_title_information.jpg) center center no-repeat;
		background-size: cover;
	}
	.paginate{
		a{
			border:none;
		}
	}
	/*.icon-pdf{
		&:before{
			content: url(../../common/images/PDF_32.png);
			position: absolute;
			left: 140px;
    		top: 15px;
			@media only screen and (max-width: $BREAK_TAB){
				top: 5px;
			}
			@media only screen and (max-width: $BREAK_SP){
				left: 100px;
			}
		}
	}*/
}
