//base
@import url('https://fonts.googleapis.com/css?family=EB+Garamond|Noto+Serif+JP|Roboto:700&subset=japanese');
@font-face {
 	font-family: 'iconfont';
  src:
	  url('../fonts/iconfont.woff2') format('woff2'),
		url('../fonts/iconfont.woff') format('woff'),
    url('../fonts/iconfont.svg') format('svg'),
    url('../fonts/iconfont.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

[class^="icon-"]:before, [class*=" icon-"]:before {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'iconfont' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.icon-facebook::before {
  content: "\ea02";
}

.icon-succession::before {
  content: "\ea03";
}

.icon-ma::before {
  content: "\ea0b";
}

.icon-inheritance::before {
  content: "\ea0c";
}

.icon-estate::before {
  content: "\ea06";
}

.icon-twitter::before {
  content: "\ea07";
}

.icon-phone::before {
  content: "\ea08";
}

.icon-mail::before {
  content: "\ea09";
}

.icon-arrow::before {
  content: "\ea0a";
}



*{box-sizing:border-box; word-break: break-all;}
html{
	-webkit-text-size-adjust: none;
	font-size: 62.5%;
}

img{vertical-align: bottom;}
ol{padding-left:20px; list-style-type:decimal;}

strong{	font-weight: bold;}
sup,sub{height: 0; line-height: 1; vertical-align: baseline;  _vertical-align: bottom; position: relative; font-size:1rem;}
sup{bottom: 1ex;}
sub{top: .5ex;}

body{
	font-size:$FONT_SIZE;font-family:$FONT_FAMILY; color:$FONT_COLOR;line-height: $LINE_HEIGHT;letter-spacing: 0.05em;
}
body.ie{
  font-family: $IE_FF;
}


.noto{
	font-family: YakuHanJP,'Noto Serif JP', serif;
}
.serif{
	font-family: 'EB Garamond', serif;
}
.roboto{
	font-family: 'Roboto', sans-serif;font-weight: 700;
}
